import React, {useEffect, useState} from 'react';
import HeaderSection from './components/Header';
import HeroSection from './components/Hero';
import FeaturesSection from './components/Features';
import FooterSection from './components/Footer';
import {Container, createTheme, ThemeProvider} from '@mui/material';
import {mainTheme} from './styles';
import './App.scss';
import SimpleContactSection from "./components/SimpleContact";


const theme = createTheme(mainTheme);

export const AppBarHeight = 64;  // Adjust this value based on your AppBar's actual height
const sectionsData = [
  { Component: HeroSection, id: 'hero' },
  { Component: FeaturesSection, id: 'features' },
  { Component: SimpleContactSection, id: 'contact' }
];

function App() {
  const [activeSection, setActiveSection] = useState('');

  const handleScroll = () => {
    const foundSection = sectionsData.find(section => {
      const element = document.getElementById(section.id);
      if (element) {
              const bounding = element.getBoundingClientRect();
              if (bounding.top <= AppBarHeight+1 && bounding.bottom >= AppBarHeight+1) {
                return true;
              }
            }
            return false;
            });

    if (foundSection) {
      setActiveSection(foundSection.id);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ width: '100%' }}>
        <HeaderSection activeSection={activeSection} />
        {sectionsData.map((section, index) => {
          return (
            <div key={section.id} id={section.id}>
              <section.Component {...section.props} />
            </div>
          );
        })}
        <FooterSection />
      </Container>
    </ThemeProvider>
  );
}

export default App;
