import React from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const FooterSection = () => {
  return (
    <Box sx={{ bgcolor: 'primary.dark', color: 'primary.contrastText', py: 2, textAlign: 'center' }}>
      <Typography variant="body1" sx={{ mb: 1 }}>
        Follow us:
      </Typography>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item>
          <IconButton color="inherit" href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <TwitterIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton color="inherit" href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton color="inherit" href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 2 }}>
        © 2024 Cloelsa. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default FooterSection;
