import React, {useEffect, useState} from 'react';
import {AppBar, Button, IconButton, Slide, Toolbar, useMediaQuery, useScrollTrigger, useTheme} from '@mui/material';
import {Link} from 'react-scroll';
import {AppBarHeight} from './../App';
import MenuIcon from '@mui/icons-material/Menu';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let scrollTimer = null;

    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => setIsScrolling(false), 150); // Adjust the timeout duration as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimer);
    };
  }, []);

  const shouldShow = !trigger || !isScrolling;

  return (
    <Slide appear={false} direction="down" in={shouldShow}>
      {children}
    </Slide>
  );
}


const navLinks = [
  { id: 'hero', label: 'Welcome' },
  { id: 'features', label: 'Features' },
  { id: 'contact', label: 'Contact' }
];

const HeaderSection = ({ activeSection }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <HideOnScroll>
      <AppBar position="fixed" color="background" elevation={1}>
        <Toolbar>
          <img src="/logo.webp" alt="Logo" style={{ width: '60px', height: '60px', marginRight: 'auto' }} />
          {isMobile ? (
            <IconButton color="inherit" edge="end">
              <MenuIcon />
            </IconButton>
          ) : (
            navLinks.map((link) => (
              <Link key={link.id} to={link.id} smooth={true} duration={500} offset={-AppBarHeight}>
                <Button color={activeSection === link.id ? 'primary' : 'inherit'}>
                  {link.label}
                </Button>
              </Link>
            ))
          )}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default HeaderSection;
