import React, {forwardRef} from 'react';
import {Box, Button, Typography} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const SimpleContactSection = forwardRef((props, ref) => {
    return (
        <Box id="contact" ref={ref} sx={{ py: 6, bgcolor: 'background.paper', color: 'primary.main', textAlign: 'center' }}>
            <Typography color="primary" variant="h4" component="h2" gutterBottom>
                Contact Us
            </Typography>
            <Typography variant="subtitle1" component="span">
                We'd love to hear from you.{" "}

                    <Button
                        color="primary"
                        href="mailto:info@cloelsa.com"
                        sx={{
                            textTransform: 'none', // This prevents the text from being capitalized
                        }}
                    >
                        <EmailOutlinedIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Send us a message
                    </Button>
                and we'll respond as soon as possible.
            </Typography>
        </Box>
    );
});

export default SimpleContactSection;
