export const mainTheme = {
    palette: {
        background: {
            main: '#f8fafb', // Used for the background color
        },
        primary: {
            main: '#4c6e80', // Your primary color
            contrastText: '#f8fafb', // Ensuring text on primary is readable
        },
        secondary: {
            main: '#cd685c', // Your secondary color
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h4: {
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginBottom: '2rem',
                }
            },
        },
        MuiBox: {
            styleOverrides: {
                root: {
                    padding: '2rem 0',
                },
            },
        },
    },
};
