import React, {forwardRef} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {motion} from 'framer-motion';
import {AppBarHeight} from "../App";
import {Link} from "react-scroll";

const HeroSection = forwardRef((props, ref) => {
    return (
        <div id="hero" ref={ref}>
            <Box
                sx={{
                    height: '100vh',
                    backgroundImage: 'url("https://source.unsplash.com/1920x1080/?nature,dark")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <motion.div
                    initial={{y: -250}}
                    animate={{y: -10}}
                    transition={{type: 'spring', stiffness: 100}}
                >
                    <Typography variant="h2" color="primary.contrastText">
                        Welcome to Cloelsa
                    </Typography>
                    <Typography variant="h5" color="primary.contrastText" sx={{my: 2}}>
                        Building Solutions Around Artificial Intelligence
                    </Typography>
                    <Link key={"features"} to={"features"} smooth={true} duration={500} offset={-AppBarHeight}>
                        <Button variant="contained" color="primary" size="large">
                            Learn More
                        </Button>
                    </Link>
                </motion.div>
            </Box>
        </div>
    );
});

export default HeroSection;
